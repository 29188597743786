<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>CASOS</h3>
            |
            <v-icon color="primary">mdi-home</v-icon>
            <v-icon>mdi-chevron-double-right</v-icon>
            LISTA DE DESARMONÍAS
          </v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            v-if="$can('handle_self_justice')"
            :to="{
              name: 'selfjustice.createdisharmony',
            }"
            class="mb-2"
            color="primary"
            dark
            rounded
          >
            Nueva Desarmonía
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" lg="2" md="4" sm="12">
            <v-autocomplete
              v-model="filters.disharmony_id"
              :items="disharmonys_types"
              single
              item-text="name"
              item-value="id"
              label="Desarmonía"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <v-autocomplete
              v-model="filters.jurisdictions_id"
              :items="references_types.jurisdictions"
              single
              item-text="name"
              item-value="id"
              label="Competencia"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <v-dialog
              ref="dialog"
              v-model="dialog"
              :return-value.sync="filters.dates"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="filters.dates"
                  label="Rango de fechas"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @click:clear="handleClearMinDate"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dates"
                scrollable
                range
                locale="es-co"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog.save(filters.dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <v-autocomplete
              :items="dataSource.data.Territorio"
              item-text="descripcion"
              item-value="territorio_id"
              single
              label="Territorio"
              v-model="filters.territory_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <!-- municipality_id autocomplete -->
            <v-autocomplete
              :items="dataSource.data.Municipio"
              item-text="descripcion"
              item-value="municipio_id"
              single
              label="Municipio"
              v-model="filters.municipality_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <!-- case state autocomplete -->
            <v-autocomplete
              :items="selfJusticeReference.case_states"
              item-text="name"
              item-value="id"
              single
              label="Estado"
              v-model="filters.state_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" lg="2" md="4" sm="12">
            <v-btn
              @click="onPaginate()"
              class="mt-2"
              color="primary"
              dark
              rounded
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" lg="4" md="4" sm="12">
            <v-text-field
              v-model="filters.search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar por cedula"
              single-line
              @keyup.enter="onPaginate()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          :headers="headers"
          :items="dishamories"
          item-key="id"
          :items-per-page="10"
          :server-items-length="filtrado?.count"
          v-on:update:options="onPaginate"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
        >
          <template v-slot:item.current_state="{ item }">
            <v-chip outlined color="grey" text-color="black" x-small>
              {{ item.current_state?.name || "INICIAL" }}
            </v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Gestionar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {
  listDisharmony,
  typeList,
  listReferences,
} from "@/services/SelfJustice";
import Swal from "sweetalert2";
import getEventStateColor from "../../utils/eventStateColors";
import {debounce} from "lodash";
import { mapState } from "vuex";

export default {
  name: "ListOfCases.vue",
  data() {
    return {
      dialog: false,
      disharmonys_types: [],
      filters: {
        dates: [],
        disharmony_id: "",
        state_id: "",
        jurisdictions_id: "",
        municipality_id: "",
        search : ""
      },
      dishamories: [],
      municipalities: [],
      territories: [],
      sidewalks: [],
      events: [],
      references_types: [],
      search: "",
      sortBy: 'id',
      sortDesc: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "CODIGO", value: "case_code", sortable: false },
        { text: "TIPO DE DESARMONÍA", value: "disharmony", sortable: false },
        { text: "TERRITORIO", value: "territory", sortable: false },
        { text: "FECHA RECEPCIÓN", value: "reception_datetime" },
        { text: "T.RECEPCIÓN", value: "reception_type", sortable: false },
        { text: "MUNCIPIO DEL HECHO", value: "municipality", sortable: false },
        { text: "COMPETENCIA", value: "jurisdiction", sortable: false },
        { text: "ESTADO", value: "current_state", sortable: false },
        { text: "ACCIONES", value: "action", sortable: false },
      ],
      filtrado: {},
    };
  },
  methods: {
    handleClearMinDate(){
      this.filters.dates = []
    },
    getCurrentFilter() {
      return {
        ...(this.search && { search: this.search }),
      };
    },
    searchCases(pattern){
      this.filters.search = pattern;
      this.onPaginate();
    },
    onPaginate: async function (
      pagination = { sortBy: [], sortDesc: [], itemsPerPage: 15, page: 1 }
    ) {
      const { sortBy, sortDesc, itemsPerPage, page } = pagination;
      this.currentItemsPerPage = itemsPerPage;
      this.filtrado = await this.list({
        page,
        itemsPerPage,
        ...this.getCurrentFilter(),
        ...this.filters,
        ...(sortBy[0] && {
          [`${sortBy[0]}`]: sortDesc[0],
        }),
      });
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
    editCase(id) {
      this.$router.push({
        name: "selfjustice.updatedisharmony",
        params: {
          id: id,
        },
      });
    },
    async list(options) {
      try{
        this.showLoader();
        const listDishamoryResponse = (await listDisharmony(options))?.data;
        this.dishamories = listDishamoryResponse?.results.map((x) => {
          return {
            id: x.id,
            disharmony: x.disharmony,
            territory: x.territory,
            // date: x.date,
            case_code : x.case_code,
            reception_datetime: x.reception_datetime,
            jurisdiction: x.jurisdiction,
            reception_type: x.reception_type,
            municipality: x.municipality,
            territory_type: x.territory_type,
            current_state: x.current_state,
          };
        });
        this.hideLoader();
        return listDishamoryResponse;
      }catch (e) {
        this.hideLoader();
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error al cargar los casos",
        });
      }
    },
  },
  async created() {
    try {
      this.showLoader();
      const eventTypeDishResponse = await typeList();
      this.disharmonys_types = eventTypeDishResponse.data;
      const eventTypeRefResponse = await listReferences();
      this.references_types = eventTypeRefResponse.data;
      this.debounceSearch = debounce(this.searchCases, 500);
      this.hideLoader();
    } catch (err) {
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
  computed: {
    ...mapState(["dataSource", "selfJusticeReference"]),
  },
};
</script>

<style scoped></style>
